<template>
  <a-modal v-model:visible="windowShow" width="70%" footer="" :bodyStyle="{ paddingRight: 0 }" :getContainer="false" @cancel="closeClear">
    <template #title>
      <div>
        <h3 style="font-weight: bold;">{{ title }}{{ searchForm.vinNo }}</h3>
      </div>
    </template>
    <div>
      <a-form layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-input v-model:value="searchForm.vinNo" placeholder="车架号" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.orderId" placeholder="合同号" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.carrierName" placeholder="承运商" allowClear />
        </a-form-item>
        <a-form-item label="">
          <!-- <a-cascader :options="$store.state.app.PClist" v-model:value="startData" :show-search="{ filter }" :changeOnSelect="true"
            @change="setStartAddress" placeholder="起点" allowClear /> -->
          <a-input v-model:value="searchForm.startAddress" placeholder="起点" allowClear />
        </a-form-item>
        <a-form-item label="">
          <!-- <a-cascader :options="$store.state.app.PClist" v-model:value="endData" :show-search="{ filter }" :changeOnSelect="true"
            @change="setEndAddress" placeholder="终点" allowClear /> -->
          <a-input v-model:value="searchForm.endAddress" placeholder="终点" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-select style="width: 100%" v-model:value="searchForm.vehicleNum" allowClear
            :getPopupContainer="e => e.parentNode" placeholder="台量类型">
            <a-select-option :value="1">散车</a-select-option>
            <a-select-option :value="2">批量车</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" v-show="searchForm.transportType === 4">
          <a-date-picker style="width: 100%" v-model:value="searchForm.departureTimeStart"
            :getCalendarContainer="e => e.parentNode" @change="entrustStart" placeholder="委托时间起" allowClear />
        </a-form-item>
        <a-form-item label="" v-show="searchForm.transportType === 4">
          <a-date-picker style="width: 100%" v-model:value="searchForm.departureTimeEnd"
            :getCalendarContainer="e => e.parentNode" @change="entrustEnd" placeholder="委托时间终" allowClear />
        </a-form-item>
        <a-form-item label="" v-show="searchForm.transportType !== 2 && searchForm.transportType !== 3">
          <a-date-picker style="width: 100%" v-model:value="searchForm.createTimeStart"
            :getCalendarContainer="e => e.parentNode" @change="orderStart" placeholder="下单时间起" allowClear />
        </a-form-item>
        <a-form-item label="" v-show="searchForm.transportType !== 2 && searchForm.transportType !== 3">
          <a-date-picker style="width: 100%" v-model:value="searchForm.createTimeEnd"
            :getCalendarContainer="e => e.parentNode" @change="orderEnd" placeholder="下单时间终" allowClear />
        </a-form-item>
        <a-form-item label="" v-show="searchForm.transportType === 2 || searchForm.transportType === 3">
          <a-date-picker style="width: 100%" v-model:value="searchForm.lastUnloadTimeStart"
            :getCalendarContainer="e => e.parentNode" @change="lastUnloadTimeStart" placeholder="上段时间起" allowClear />
        </a-form-item>
        <a-form-item label="" v-show="searchForm.transportType === 2 || searchForm.transportType === 3">
          <a-date-picker style="width: 100%" v-model:value="searchForm.lastUnloadTimeEnd"
            :getCalendarContainer="e => e.parentNode" @change="lastUnloadTimeEnd" placeholder="上段时间终" allowClear />
        </a-form-item>
        <a-form-item label="" v-show="searchForm.transportType === 0 || searchForm.transportType === 1">
          <a-date-picker style="width: 100%" v-model:value="searchForm.pickAppointTimeStart"
            :getCalendarContainer="e => e.parentNode" @change="pickAppointTimeStart" placeholder="预约时间起" allowClear />
        </a-form-item>
        <a-form-item label="" v-show="searchForm.transportType === 0 || searchForm.transportType === 1">
          <a-date-picker style="width: 100%" v-model:value="searchForm.pickAppointTimeEnd"
            :getCalendarContainer="e => e.parentNode" @change="pickAppointTimeEnd" placeholder="预约时间终" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-date-picker style="width: 100%" v-model:value="searchForm.unloadingTimeStart"
            :getCalendarContainer="e => e.parentNode" @change="unloadingTimeStart" placeholder="卸车时间起" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-date-picker style="width: 100%" v-model:value="searchForm.unloadingTimeEnd"
            :getCalendarContainer="e => e.parentNode" @change="unloadingTimeEnd" placeholder="卸车时间终" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-space>
            <a-button :loading="loading" type="primary" @click="searchData">
              查询
            </a-button>
            <a-button @click="resetData"> 重置 </a-button>
          </a-space>
        </a-form-item>
      </a-form>
      <a-table :columns="[sortColumn, ...columns]" :row-key="(record) => record.id" :data-source="listData" bordered
        :loading="loading" size="small" :rowKey="(record, index) => { return index }" :pagination="pagination"
        @change="handleTableChange" :scroll="{ y: 500 }">
        <template #sort="{ index }">
          {{ index + 1 }}
        </template>
        <template #vehBrand="{ record }">
          <div>
            <div><a @click="detailOpen(record)">{{ record.vinNo }}</a></div>
            <div>{{ record.brand + record.model }}</div>
          </div>
        </template>
        <template #overText="{ record }">
          <div>
            <div v-for="(item, index) in record.overText" :key="index">{{ item }}</div>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="detailShow" footer="" :getContainer="false" width="65%"
      :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
      <template #title>
        <WindowDetail v-if="detailData.vinNo" :detailData="detailData" />
      </template>
      <div class="left-content" style="width: auto">
        <TransportModel ref="transportModelRef" :windowShow="detailShow" :data="detailData" :detailType="true"
          @statusShow="transportModelShowStatus" />
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { timeoutVehicle } from '@/api/dataCenter/monitoring'
import WindowDetail from '@/views/components/vinNo/windowDetail.vue'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'

export default {
  props: {
    date: String,
    searchFormData: Object,
    departureData: Object
  },
  components:{
    WindowDetail,
    TransportModel
  },
  setup (props) {
    const transportModelRef = ref(null)
    const state = reactive({
      windowShow: false,
      loading: false,
      detailShow: false,
      title: '',
      startData: [],
      endData: [],
      detailData: {},
      searchForm: {
        vinNo: null,
        orderId: null,
        carrierName: null,
        date: null,
        departure: null,
        departureId: null,
        transportType: null,
        vehicleNum: null,
        createTimeStart: null,
        createTimeEnd: null,
        departureTimeStart: null,
        departureTimeEnd: null,
        lastUnloadTimeStart: null,
        lastUnloadTimeEnd: null,
        pickAppointTimeStart: null,
        pickAppointTimeEnd: null,
        unloadingTimeStart: null,
        unloadingTimeEnd: null,
        startAddress: null,
        endAddress: null
      },
      listData: [],
      sortColumn: { title: '序号', width: '4%', slots: { customRender: 'sort' } },
      columns: [],
      entrustColumns: [
        {
          title: '车牌/车型',
          dataIndex: 'vehBrand',
          width: '8%',
          slots: {
            customRender: 'vehBrand'
          }
        },
        {
          title: '订单号',
          align: 'center',
          width: '13%',
          dataIndex: 'orderId'
        },
        {
          title: '业务员',
          width: '5%',
          align: 'center',
          dataIndex: 'salesman'
        },
        {
          title: '起点',
          align: 'center',
          width: '10%',
          dataIndex: 'startAddress'
        },
        {
          title: '终点',
          align: 'center',
          width: '10%',
          dataIndex: 'endAddress'
        },
        {
          title: '台量类型',
          width: '7%',
          align: 'center',
          dataIndex: 'vehicleNum.label'
        },
        {
          title: '承运商',
          width: '10%',
          align: 'center',
          dataIndex: 'carrierName'
        },
        {
          title: '下单时间',
          align: 'center',
          width: '8%',
          dataIndex: 'createTime'
        },
        {
          title: '委托完成时间',
          width: '8%',
          align: 'center',
          dataIndex: 'departureTime'
        },
        {
          title: '超时小时',
          width: '6%',
          align: 'center',
          dataIndex: 'timeoutHour'
        }
      ],
      inspectColumns: [
        {
          title: '车牌/车型',
          dataIndex: 'vehBrand',
          width: '8%',
          slots: {
            customRender: 'vehBrand'
          }
        },
        {
          title: '订单号',
          align: 'center',
          width: '13%',
          dataIndex: 'orderId'
        },
        {
          title: '业务员',
          width: '5%',
          align: 'center',
          dataIndex: 'salesman'
        },
        {
          title: '起点',
          align: 'center',
          width: '10%',
          dataIndex: 'startAddress'
        },
        {
          title: '终点',
          align: 'center',
          width: '10%',
          dataIndex: 'endAddress'
        },
        {
          title: '台量类型',
          width: '7%',
          align: 'center',
          dataIndex: 'vehicleNum.label'
        },
        {
          title: '承运商',
          width: '8%',
          align: 'center',
          dataIndex: 'carrierName'
        },
        {
          title: '下单时间',
          align: 'center',
          width: '8%',
          dataIndex: 'createTime'
        },
        {
          title: '预约时间',
          align: 'center',
          width: '8%',
          dataIndex: 'pickAppointTime'
        },
        {
          title: '验车完成时间',
          width: '8%',
          align: 'center',
          dataIndex: 'unloadingTime'
        },
        {
          title: '超时小时',
          width: '6%',
          align: 'center',
          dataIndex: 'timeoutHour'
        }
      ],
      pickColumns: [
        {
          title: '车牌/车型',
          dataIndex: 'vehBrand',
          width: '8%',
          slots: {
            customRender: 'vehBrand'
          }
        },
        {
          title: '订单号',
          align: 'center',
          width: '13%',
          dataIndex: 'orderId'
        },
        {
          title: '业务员',
          width: '5%',
          align: 'center',
          dataIndex: 'salesman'
        },
        {
          title: '起点',
          align: 'center',
          width: '10%',
          dataIndex: 'startAddress'
        },
        {
          title: '终点',
          align: 'center',
          width: '10%',
          dataIndex: 'endAddress'
        },
        {
          title: '台量类型',
          width: '7%',
          align: 'center',
          dataIndex: 'vehicleNum.label'
        },
        {
          title: '承运商',
          width: '8%',
          align: 'center',
          dataIndex: 'carrierName'
        },
        {
          title: '下单时间',
          align: 'center',
          width: '8%',
          dataIndex: 'createTime'
        },
        {
          title: '预约时间',
          align: 'center',
          width: '8%',
          dataIndex: 'pickAppointTime'
        },
        {
          title: '提车完成时间',
          width: '6%',
          align: 'center',
          dataIndex: 'unloadingTime'
        },
        {
          title: '超时小时',
          width: '6%',
          align: 'center',
          dataIndex: 'timeoutHour'
        }
      ],
      sendColumns: [
        {
          title: '车牌/车型',
          dataIndex: 'vehBrand',
          width: '8%',
          slots: {
            customRender: 'vehBrand'
          }
        },
        {
          title: '订单号',
          align: 'center',
          width: '13%',
          dataIndex: 'orderId'
        },
        {
          title: '业务员',
          width: '5%',
          align: 'center',
          dataIndex: 'salesman'
        },
        {
          title: '起点',
          align: 'center',
          width: '10%',
          dataIndex: 'startAddress'
        },
        {
          title: '终点',
          align: 'center',
          width: '10%',
          dataIndex: 'endAddress'
        },
        {
          title: '台量类型',
          width: '7%',
          align: 'center',
          dataIndex: 'vehicleNum.label'
        },
        {
          title: '承运商',
          width: '8%',
          align: 'center',
          dataIndex: 'carrierName'
        },
        {
          title: '上段完成时间',
          align: 'center',
          width: '8%',
          dataIndex: 'lastUnloadTime'
        },
        {
          title: '送车完成时间',
          width: '6%',
          align: 'center',
          dataIndex: 'unloadingTime'
        },
        {
          title: '超时小时',
          width: '6%',
          align: 'center',
          dataIndex: 'timeoutHour'
        }
      ],
      mainLineColumns: [
        {
          title: '车牌/车型',
          dataIndex: 'vehBrand',
          width: '8%',
          slots: {
            customRender: 'vehBrand'
          }
        },
        {
          title: '订单号',
          align: 'center',
          width: '13%',
          dataIndex: 'orderId'
        },
        {
          title: '业务员',
          width: '5%',
          align: 'center',
          dataIndex: 'salesman'
        },
        {
          title: '起点',
          align: 'center',
          width: '10%',
          dataIndex: 'startAddress'
        },
        {
          title: '终点',
          align: 'center',
          width: '10%',
          dataIndex: 'endAddress'
        },
        {
          title: '台量类型',
          width: '7%',
          align: 'center',
          dataIndex: 'vehicleNum.label'
        },
        {
          title: '本段公里数',
          width: '8%',
          align: 'center',
          dataIndex: 'comKm'
        },
        {
          title: '承运商',
          width: '8%',
          align: 'center',
          dataIndex: 'carrierName'
        },
        {
          title: '上段完成时间',
          align: 'center',
          width: '8%',
          dataIndex: 'lastUnloadTime'
        },
        {
          title: '本段完成时间',
          width: '6%',
          align: 'center',
          dataIndex: 'unloadingTime'
        },
        {
          title: '超时小时',
          width: '6%',
          align: 'center',
          dataIndex: 'timeoutHour'
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`,
      }
    })
    const getPropsData = () => {
      if (props.searchFormData) {
        state.searchForm.date = props.searchFormData.date
        state.searchForm.orderBusiness = props.searchFormData.orderBusiness
        state.searchForm.transportType = props.searchFormData.transportType
        state.searchForm.vehicleNum = props.searchFormData.vehicleNum
        state.searchForm.comKmStart = props.searchFormData.comKmStart
        state.searchForm.comKmEnd = props.searchFormData.comKmEnd
      }
      if (props.departureData !== undefined) {
        state.searchForm.departure = props.departureData.departure
        state.searchForm.departureId = props.departureData.departureId
      }
    }
    const detailOpen = record => {
      state.detailData = record
      state.detailShow = true
      setTimeout(() => {
        transportModelRef.value.getTaskListData()
      }, 50)
    }
    const loadData = () => {
      state.loading = true
      timeoutVehicle({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).finally(() => { state.loading = false })
    }
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const handleTableChange = page => {
      state.pagination = page
      loadData()
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      getPropsData()
      searchData()
    }
    const openWindow = () => {
      state.windowShow = true
    }
    const closeClear = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.pagination.current = 1
    }
    const entrustStart = (e,v) => {
      state.searchForm.departureTimeStart = v
    }
    const entrustEnd = (e,v) => {
      state.departureTimeEnd = v
    }
    const orderStart = (e, v) => {
      state.searchForm.orderTimeStart = v
    }
    const orderEnd = (e, v) => {
      state.searchForm.orderTimeEnd = v
    }
    const unloadingTimeStart = (e, v) => {
      state.searchForm.unloadingTimeStart = v
    }
    const unloadingTimeEnd = (e, v) => {
      state.searchForm.unloadingTimeEnd = v
    }
    const pickAppointTimeStart = (e, v) => {
      state.searchForm.pickAppointTimeStart = v
    }
    const pickAppointTimeEnd = (e, v) => {
      state.searchForm.pickAppointTimeEnd = v
    }
    const lastUnloadTimeStart = (e, v) => {
      state.searchForm.lastUnloadTimeStart = v
    }
    const lastUnloadTimeEnd = (e, v) => {
      state.searchForm.lastUnloadTimeEnd = v
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
      state.searchForm.startAddress.area = value[2]
    }
    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
      state.searchForm.endAddress.area = value[2]
    }
    return {
      ...toRefs(state),
      transportModelRef,
      openWindow,
      getPropsData,
      detailOpen,
      closeClear,
      orderStart,
      orderEnd,
      entrustStart,
      entrustEnd,
      unloadingTimeStart,
      unloadingTimeEnd,
      pickAppointTimeStart,
      pickAppointTimeEnd,
      lastUnloadTimeStart,
      lastUnloadTimeEnd,
      setStartAddress,
      handleTableChange,
      searchData,
      resetData,
      loadData,
      setEndAddress
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-form-item) {
  width: 18%;
  margin: 5px;
}
</style>
