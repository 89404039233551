
import * as echarts from 'echarts'
import china from 'echarts/map/json/china.json'
let mTime = null

// 中国地图区域
export const getChinaData = (box, dataList, styleCompatible) => {
  modelDemo()
  function modelDemo () {
    echarts.registerMap('china', china)
    // 基于准备好的dom，初始化echarts实例
    var myChart = echarts.init(document.getElementById(box))

    var name_title = ''
    var subname = ''
    var nameColor = ' rgb(55, 75, 113)'
    var name_fontFamily = '楷体'
    var name_fontSize = 52
    var mapName = 'china'
    var dataIndex = 0
    let maxDataIndex = 0
    // var mTime = null
    var data = []
    const indexList = []
    var option = []
    var geoCoordMap = {}
    var toolTipData = []
    if (mTime !== null) {
      clearInterval(mTime)
    }
    /* 获取地图数据 */
    myChart.showLoading()
    var mapFeatures = echarts.getMap('china', china).geoJson.features
    myChart.hideLoading()
    mapFeatures.forEach(function (v, i) {
      // 地区名称
      var name = v.properties.name
      // 地区经纬度
      // geoCoordMap[name] = v.properties.cp;
      const index = dataList.findIndex(item => item.id == v.properties.id)
      if (index >= 0) {
        data.push({
          name: name,
          // queryId: dataList[index].queryId,
          value: dataList[index].despatchNum * 1 > 0 ? dataList[index].onTimeRate : -50,
          despatchNum: dataList[index].despatchNum,
          timeoutNum: dataList[index].timeoutNum,
          onTimeRate: dataList[index].onTimeRate
          // punctuality: dataList[index].punctuality,
          // rank: dataList[index].rank
        })
        if (dataList[index].despatchNum > 0) {
          indexList.push(i)
          maxDataIndex++
        }
      }
      // toolTipData.push({
      //   name: name,
      //   value: [{
      //     name: '发运台量',
      //     value: Math.round(Math.random() * 100 + 10)
      //   },
      //   {
      //     name: '超时台量',
      //     value: Math.round(Math.random() * 100 + 10)
      //   },
      //   {
      //     name: '准时率',
      //     value: Math.round(Math.random() * 100 + 10)
      //   },
      //   {
      //     name: '排名',
      //     value: Math.round(Math.random() * 100 + 10)
      //   }
      //   ]
      // })
    })
    indexList.sort((a, b) => b - a)
    option = {
      title: {
        show: false,
        text: name_title,
        subtext: subname,
        x: 'center',
        textStyle: {
          color: nameColor,
          fontFamily: name_fontFamily,
          fontSize: name_fontSize
        }
      },
      grid: {
        width: '100%',
        height: '100%',
        top: 'center',
        bottom: 'center',
        left: 'center',
        right: 'center',
      },
      tooltip: {
        trigger: 'item',
        extraCssText: 'z-index:1',
        formatter: function (params) {
          if (params.data) {
            let toolTiphtml = ''
            toolTiphtml = `<div style='color:#ffc023'>${params.data.name}</div>`
            toolTiphtml += `<div>发运台次：${params.data.despatchNum}</div>`
            toolTiphtml += `<div>超时台次：${params.data.timeoutNum}</div>`
            toolTiphtml += `<div>准时率：${params.data.onTimeRate + '%'}</div>`
            return toolTiphtml
          }
        }
      },
      legend: {
        orient: 'vertical',
        y: 'bottom',
        x: 'right',
        data: ['credit_pm2.5'],
        textStyle: {
          color: '#fff'
        }
      },
      visualMap: {
        show: true,
        min: -50,
        max: 100,
        color: '#fff',
        left: 'left',
        top: 'bottom',
        text: ['高', '低'], // 文本，默认为数值文本
        calculable: true,
        // seriesIndex: [1],
        textStyle: { color: '#fff' },
        inRange: {
          color: ['#fff', '#9A0103', '#ffc023', '#2636FF']
        }
      },
      /* 工具按钮组 */
      toolbox: {
        show: false,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          dataView: {
            readOnly: false
          },
          restore: {},
          saveAsImage: {}
        }
      },
      geo: {
        show: true,
        map: mapName,
        center: [10, 50],
        label: {
          normal: {
            show: false
          },
          emphasis: {
            show: false
          }
        },
        roam: true,
        itemStyle: {
          normal: {
            areaColor: '#031525',
            borderColor: '#3B5077'
          },
          emphasis: {
            areaColor: '#2B91B7'
          }
        }
      },
      series: [
        {
          type: 'map',
          map: mapName,
          // geoIndex: 0,
          // aspectScale: 0.75, //长宽比
          zoom: 1.2,
          showLegendSymbol: false, // 存在legend时显示
          label: {
            normal: {
              color: '#fff',
              show: true
            },
            emphasis: {
              show: false,
              textStyle: {
                color: '#fff'
              }
            }
          },
          roam: false,
          itemStyle: {
            normal: {
              areaColor: '#2736ff',
              borderColor: '#0227ad'
            },
            emphasis: {
              areaColor: '#2B91B7',
              borderType: 'solid',
              borderWidth: 2,
              borderColor: '#f00'
            }
          },
          animation: false,
          data: data
        }
      ]
    }
    myChart.setOption(option)
    myChart.off('click')
    myChart.off('mousemove')
    myChart.off('mouseout')
    myChart.on('click', function (params) {
      // 控制台打印数据的名称
      if (params.data.queryId) chinaSearch(Number(params.data.queryId))
    })
    if (mTime === null) {
      mTime = setInterval(() => {
        // 清除之前的高亮
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: indexList[dataIndex === 0 ? maxDataIndex : dataIndex - 1]
        })
        // 当前下标高亮
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: indexList[dataIndex]
        })
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: indexList[dataIndex]
        })
        dataIndex++
        if (dataIndex >= indexList.length) {
          dataIndex = 0
        }
      }, 3000)
    }
    myChart.on('mousemove', (e) => {
      clearInterval(mTime)
      if (mTime !== null) {
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: indexList[dataIndex === 0 ? maxDataIndex : dataIndex - 1]
        })
        mTime = null
      }
    })
    myChart.on('mouseout', (e) => {
      if (mTime === null) {
        mTime = setInterval(() => {
          // 清除之前的高亮
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: indexList[dataIndex === 0 ? maxDataIndex : dataIndex - 1]
          })
          // 当前下标高亮
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: indexList[dataIndex]
          })
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: indexList[dataIndex]
          })
          dataIndex++
          if (dataIndex >= indexList.length) {
            dataIndex = 0
          }
        }, 3000)
      }
    })
    myChart.off('resize')
    // 使用刚指定的配置项和数据显示图表。
    window.addEventListener('resize', function () {
      styleCompatible()
      myChart.resize()
    })
    setTimeout(() => {
      myChart.setOption(option)
    }, 10)
  }
}