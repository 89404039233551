
<template>
  <div class="body-content" id="fullScreenDiv">
    <div class="loading" v-show="loading"><a-spin size="large" /></div>
    <div class="body-top">
      <a-month-picker v-model:value="searchForm.date" format="YYYY-MM" @change="monthChange"
        :getCalendarContainer="e => e.parentNode">
        <a style="color: #fff; font-size: 18px">{{ searchForm.date }}
          <CaretRightOutlined />
        </a>
      </a-month-picker>
      <div style="height: 100%; flex: 1" @dblclick="fullScreen"></div>
      <span style="color: #fff">{{ dateData }}</span>
    </div>
    <!-- 占位符 -->
    <div style="height: 9%; display: flex; justify-content: center; align-items: center">
      <!-- <img src="@/assets/images/monitoring/title.png" /> -->
    </div>
    <div class="content-div">
      <div class="left-div">
        <a-space class="search-form" :size="4">
          <a-select :getPopupContainer="e => e.parentNode" style="width: 100px" v-model:value="placeholderValue" :placeholder="placeholder">
            <template #suffixIcon>
              <CaretDownOutlined style="color: #fff; font-size: 24px" />
            </template>
            <a-select-option @click="groupChange" value="">调度员</a-select-option>
            <a-select-option v-for="item in groupListData" :key="item.empId" :value="empId" @click="groupChange(item)">{{
              item.empName }}</a-select-option>
          </a-select>
          <a-select :getPopupContainer="e => e.parentNode" v-model:value="searchForm.vehicleNum" style="width: 100px"
            placeholder="台量类型" @change="searchChange">
            <template #suffixIcon>
              <CaretDownOutlined style="color: #fff; font-size: 24px" />
            </template>
            <a-select-option value="">台量类型</a-select-option>
            <a-select-option :value="1">散车</a-select-option>
            <a-select-option :value="2">批量车</a-select-option>
          </a-select>
          <a-select :getPopupContainer="e => e.parentNode" style="width: 120px" placeholder="业务部门">
            <template #suffixIcon>
              <CaretDownOutlined style="color: #fff; font-size: 24px" />
            </template>
            <a-select-option @click="orgChange(null)">业务部门</a-select-option>
            <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id"
              @click="orgChange(item.id)">{{ item.name
              }}</a-select-option>
          </a-select>
        </a-space>
        <a-space class="search-form" style="margin-top:0" :size="4">
          <a-select v-model:value="searchForm.orderBusiness" :getPopupContainer="e => e.parentNode" style="width: 100px"
            placeholder="业务方" @change="searchChange">
            <template #suffixIcon>
              <CaretDownOutlined style="color: #fff; font-size: 24px" />
            </template>
            <a-select-option value="">业务方</a-select-option>
            <a-select-option :value="0">华月</a-select-option>
            <a-select-option :value="1">专线</a-select-option>
          </a-select>
          <a-select :getPopupContainer="e => e.parentNode" style="width: 120px" placeholder="调度部门">
            <template #suffixIcon>
              <CaretDownOutlined style="color: #fff; font-size: 24px" />
            </template>
            <a-select-option @click="dispatchChange(null)">调度部门</a-select-option>
            <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id"
              @click="dispatchChange(item.id)">{{ item.name
              }}</a-select-option>
          </a-select>
        </a-space>
        <div class="left-content">
          <!-- 委托准时率 -->
          <div class="center-data">
            <div class="title-div">
              <div class="data-title" @click="searchForm.transportType = 4, getProvinceOnTimeRate()">委托准时率</div>
              <div style="color: #fff; margin-bottom: 5px">
                <img src="@/assets/images/monitoring/more.png" />
              </div>
            </div>
            <div class="table-body">
              <table class="router-data">
                <thead class="table-title">
                  <tr>
                    <td class="table-td">调度员</td>
                    <td class="table-td">发运台次</td>
                    <td class="table-td">超时台次</td>
                    <td class="table-td">准时率</td>
                  </tr>
                </thead>
                <tbody style="width: 100%;">
                  <tr style="width: 100%;" v-for="(item, index) in entrustList" :key="index">
                    <td class="table-td">{{ item.departure }}</td>
                    <td class="table-td">{{ item.despatchNum }}</td>
                    <td class="table-td">
                      <a v-show="item.timeoutNum * 1 > 0 && item.departureId" @click="detailClick(item, 4)">{{
                        item.timeoutNum }}</a>
                      <span v-show="item.timeoutNum * 1 <= 0 || !item.departureId">{{ item.timeoutNum }}</span>
                    </td>
                    <td class="table-td">{{ item.onTimeRate + '%' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- 验车准时率 -->
          <div class="center-data">
            <div class="title-div">
              <div class="data-title" @click="searchForm.transportType = 0, getProvinceOnTimeRate()">验车准时率</div>
              <div style="color: #fff; margin-bottom: 5px">
                <img src="@/assets/images/monitoring/more.png" />
              </div>
            </div>
            <div class="table-body">
              <table class="router-data">
                <thead class="table-title">
                  <tr>
                    <td class="table-td">调度员</td>
                    <td class="table-td">发运台次</td>
                    <td class="table-td">超时台次</td>
                    <td class="table-td">准时率</td>
                  </tr>
                </thead>
                <tbody style="width: 100%;">
                  <tr style="width: 100%;" v-for="(item, index) in inspectMainList" :key="index">
                    <td class="table-td">{{ item.departure }}</td>
                    <td class="table-td">{{ item.despatchNum }}</td>
                    <td class="table-td">
                      <a v-show="item.timeoutNum * 1 > 0 && item.departureId" @click="detailClick(item, 0)">{{
                        item.timeoutNum }}</a>
                      <span v-show="item.timeoutNum * 1 <= 0 || !item.departureId">{{ item.timeoutNum }}</span>
                    </td>
                    <td class="table-td">{{ item.onTimeRate + '%' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- 地图 -->
      <div class="center-div">
        <div class="center-china">
          <div id="china_map"></div>
        </div>
        <div :style="{ height: centerHeight, display: 'flex', justifyContent: 'space-between' }">
          <!-- 提车准时率 -->
          <div class="center-data">
            <div class="title-div">
              <div class="data-title" @click="searchForm.transportType = 1, getProvinceOnTimeRate()">提车准时率</div>
              <div style="color: #fff; margin-bottom: 5px">
                <img src="@/assets/images/monitoring/more.png" />
              </div>
            </div>
            <div class="table-body">
              <table class="router-data">
                <thead class="table-title">
                  <tr>
                    <td class="table-td">调度员</td>
                    <td class="table-td">发运台次</td>
                    <td class="table-td">超时台次</td>
                    <td class="table-td">准时率</td>
                  </tr>
                </thead>
                <tbody style="width: 100%;">
                  <tr style="width: 100%;" v-for="(item, index) in pickMainList" :key="index">
                    <td class="table-td">{{ item.departure }}</td>
                    <td class="table-td">{{ item.despatchNum }}</td>
                    <td class="table-td">
                      <a v-show="item.timeoutNum * 1 > 0 && item.departureId" @click="detailClick(item, 1)">{{
                        item.timeoutNum }}</a>
                      <span v-show="item.timeoutNum * 1 <= 0 || !item.departureId">{{ item.timeoutNum }}</span>
                    </td>
                    <td class="table-td">{{ item.onTimeRate + '%' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- 送车准时率 -->
          <div class="center-data">
            <div class="title-div">
              <div class="data-title" @click="searchForm.transportType = 3, getProvinceOnTimeRate()">送车准时率</div>
              <div style="color: #fff; margin-bottom: 5px">
                <img src="@/assets/images/monitoring/more.png" />
              </div>
            </div>
            <div class="table-body">
              <table class="router-data">
                <thead class="table-title">
                  <tr>
                    <td class="table-td">调度员</td>
                    <td class="table-td">发运台次</td>
                    <td class="table-td">超时台次</td>
                    <td class="table-td">准时率</td>
                  </tr>
                </thead>
                <tbody style="width: 100%;">
                  <tr style="width: 100%;" v-for="(item, index) in sendMainList" :key="index">
                    <td class="table-td">{{ item.departure }}</td>
                    <td class="table-td">{{ item.despatchNum }}</td>
                    <td class="table-td">
                      <a v-show="item.timeoutNum * 1 > 0 && item.departureId" @click="detailClick(item, 3)">{{
                        item.timeoutNum }}</a>
                      <span v-show="item.timeoutNum * 1 <= 0 || !item.departureId">{{ item.timeoutNum }}</span>
                    </td>
                    <td class="table-td">{{ item.onTimeRate + '%' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <!-- 干线准时率 -->
        <div :style="{ height: mainLineHeight }">
          <div class="title-div">
            <div class="data-title" @click="searchForm.transportType = 2, getProvinceOnTimeRate()">干线准时率</div>
            <div style="color: #fff; margin-bottom: 5px">
              <img src="@/assets/images/monitoring/more.png" />
            </div>
          </div>
          <div class="search-div">
            <a-space>
              <a-input v-model:value="searchForm.comKmStart" type="number" placeholder="公里数" />
              <span style="color:#eee">—</span>
              <a-input v-model:value="searchForm.comKmEnd" type="number" placeholder="公里数" />
              <a-button :loading="loading" type="primary" @click="searchForm.transportType = 2, getCapacityData()">
                查询
              </a-button>
            </a-space>
          </div>
          <div class="table-body">
            <table class="router-data">
              <thead class="table-title">
                <tr>
                  <td class="table-td">调度员</td>
                  <td class="table-td">发运台次</td>
                  <td class="table-td">超时台次</td>
                  <td class="table-td">准时率</td>
                </tr>
              </thead>
              <tbody style="width: 100%;">
                <tr style="width: 100%;" v-for="(item, index) in mainLineList" :key="index">
                  <td class="table-td">{{ item.departure }}</td>
                  <td class="table-td">{{ item.despatchNum }}</td>
                  <td class="table-td">
                    <a v-show="item.timeoutNum * 1 > 0 && item.departureId" @click="detailClick(item, 2)">{{
                      item.timeoutNum }}</a>
                    <span v-show="item.timeoutNum * 1 <= 0 || !item.departureId">{{ item.timeoutNum }}</span>
                  </td>
                  <td class="table-td">{{ item.onTimeRate + '%' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="detailShow" width="40%" :title="detailTitle" footer="" :getContainer="false"
      :bodyStyle="{ paddingTop: '10px' }">
    </a-modal>
    <DetailModal ref="detailModalRef" :searchFormData="searchForm" :departureData="departureData" />
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import {
  CaretDownOutlined,
  CaretRightOutlined,
} from '@ant-design/icons-vue'
import DetailModal from './detailModal'
import { getChinaData } from './echarts.js'
import { dispatcherList } from '@/api/global'
import { provinceOnTimeRate, delegateOnTimeRate, onTimeRate, onTimeRateAll } from '@/api/dataCenter/monitoring'
import { getCompanyAll } from '@/api/dataCenter/monitoring'
import { getTimeYMD, getTime } from '@/utils/util'
// 引入screenfull
import screenfull from 'screenfull'

export default {
  components: {
    CaretDownOutlined,
    CaretRightOutlined,
    DetailModal
  },
  setup () {
    const detailModalRef = ref(null)
    const state = reactive({
      detailShow: false,
      loading: false,
      flag: null,
      timer: null,
      fullScreenType: null,
      dateData: '',
      placeholderValue: '',
      totalTitle: '全部',
      placeholder: '调度员',
      centerHeight: '23vh',
      mainLineHeight: '67vh',
      entrustList: [],
      mainLineList: [],
      pickMainList: [],
      sendMainList: [],
      inspectMainList: [],
      groupListData: [],
      departureData: {},
      userData: {
        empName: null,
        empType: { value: 0 }
      },
      searchForm: {
        date: null,
        orgId: null,
        orderBusiness: null,
        dispatchOrgId: null,
        transportType: null,
        vehicleNum: null,
        departure: null,
        departureId: null,
        comKmStart: null,
        comKmEnd: null
      }
    })
    onMounted(() => {
      document.querySelector('.ant-page-container').style.height = '95%'
      clearInterval(state.timer)
      state.timer = setInterval(() => {
        state.dateData = getTime(2)
      }, 1000)
      getData()
      setTimeout(() => {
        getDataAll() // 验提干送准时率
      }, 50)
    })
    const styleCompatible = () => {
      if (state.fullScreenType === null) {
        document.querySelector('.left-div').style.maxHeight = '90vh'
        state.centerHeight = '32vh'
        state.mainLineHeight = '78.5vh'
        state.fullScreenType = false
      } else {
        document.querySelector('.left-div').style.maxHeight = '80vh'
        state.centerHeight = '23vh'
        state.mainLineHeight = '67vh'
        state.fullScreenType = null
      }
    }
    const getProvinceOnTimeRate = () => {
      state.loading = true
      provinceOnTimeRate({
        ...state.searchForm
      }).then(res => {
        if (res.code === 10000) {
          const chinaData = res.data
          getChinaData('china_map', chinaData, styleCompatible)
        }
      }).finally(() => { state.loading = false })
    }
    const getData = () => {
      let d = getTimeYMD().split('-')
      state.searchForm.date = d[0] + '-' + d[1]
      // getIdentity().then(res => { // 获取默认账号数据
      //   if (res.code === 10000) {
      //     if (res.data) {
      //       state.userData = res.data
      //       state.groupListData = res.data.groupListData
      //     }
      //   }
      // })
      dispatcherList().then(res => { // 获取小组成员
        if (res.code === 10000) {
          if (res.data) {
            state.groupListData = res.data
          }
        }
      })
    }
    const getDataAll = () => {
      // transportType 0:验;1:提,2:干;3:送
      state.loading = true
      onTimeRateAll({
        ...state.searchForm,
      }).then(res => {
        if (res.code === 10000) {
          state.entrustList = res.data.delegateOnTimeList
          state.inspectMainList = res.data.inspectionOnTimeList
          state.pickMainList = res.data.pickOnTimeList
          state.mainLineList = res.data.transOnTimeList
          state.sendMainList = res.data.deliveryOnTimeList
        }
      }).finally(() => { state.loading = false })
      getProvinceOnTimeRate() // 地图
    }
    const getCapacityData = () => {
      state.loading = true
      onTimeRate({
        ...state.searchForm,
      }).then(res => {
        if (res.code === 10000) {
          state.mainLineList = res.data
        }
      }).finally(() => { state.loading = false })
    }

    // 小组成员切换
    const groupChange = item => {
      if (!item) {
        state.searchForm.departure = null, state.searchForm.departureId = null
        state.totalTitle = '调度员'
        state.placeholderValue = null
      } else {
        state.placeholder = '调度员'
        state.totalTitle = '全部'
        state.searchForm.departure = item.empName, state.searchForm.departureId = item.empId
      }
      getDataAll()
      // state.dispatchId = item.empId
      // state.smallVehList = []
      // state.statisticsVal = null
      // state.globalIndex = null
      // loadData()
      // let DOMS = document.querySelectorAll('.small-car .statistics-content')
      // for (let i = 0; i < DOMS.length; i++) {
      //   DOMS[i].style.color = '#344563'
      //   DOMS[i].style.background = ''
      // }
    }
    // 部门切换
    const orgChange = id => {
      if (id) state.searchForm.orgId = id
      else state.searchForm.orgId = null
      getDataAll()
    }
    const dispatchChange = id => {
      if (id) state.searchForm.dispatchOrgId = id
      else state.searchForm.dispatchOrgId = null
      getDataAll()
    }
    // 年份切换
    const monthChange = (e, v) => {
      state.searchForm.date = v
      // state.searchForm.year = v.split('-')[0]
      getDataAll()
    }
    // 台量类型
    const searchChange = () => {
      getDataAll()
    }
    const detailClick = (item, type) => {
      // type  0:验;1:提,2:干;3:送
      state.searchForm.transportType = type
      state.departureData.departure = item.departure
      state.departureData.departureId = item.departureId
      detailModalRef.value.openWindow()
      setTimeout(() => {
        detailModalRef.value.getPropsData()
        detailModalRef.value.loadData()
      }, 50)
      switch (type) {
        case 0:
          detailModalRef.value.columns = detailModalRef.value.inspectColumns
          detailModalRef.value.title = '验车准时率' + '-' + item.departure + '-' + '超时车辆'
          break;
        case 1:
          detailModalRef.value.columns = detailModalRef.value.pickColumns
          detailModalRef.value.title = '提车准时率' + '-' + item.departure + '-' + '超时车辆'
          break;
        case 2:
          detailModalRef.value.columns = detailModalRef.value.mainLineColumns
          detailModalRef.value.title = '干线准时率' + '-' + item.departure + '-' + '超时车辆'
          break;
        case 3:
          detailModalRef.value.columns = detailModalRef.value.sendColumns
          detailModalRef.value.title = '送车准时率' + '-' + item.departure + '-' + '超时车辆'
          break;
        case 4:
          detailModalRef.value.columns = detailModalRef.value.entrustColumns
          detailModalRef.value.title = '委托准时率' + '-' + item.departure + '-' + '超时车辆'
          break;
      }
    }
    const fullScreen = () => {
      // setTimeout(() => {
      //   // getChinaData("china_map", chinaData, chinaSearch);
      //   departmentData("department", state.orgNameList, state.orgDataList);
      //   yearStatisticsData("yearData", state.yearTitles, state.yearData);
      // }, 500);
      // 先使用screenfull.enabled方法判断是否支持screenfull
      // 如果不允许进入全屏，发出不允许提示 浏览器不能全屏
      if (!screenfull.enabled) {
        return false
      }
      const element = document.getElementById('fullScreenDiv')
      // 调用 screenfull.toggle() 可以双向切换全屏与非全屏
      // screenfull.toggle()
      screenfull.request(element)
    }
    return {
      ...toRefs(state),
      detailModalRef,
      orgChange,
      dispatchChange,
      groupChange,
      monthChange,
      searchChange,
      getDataAll,
      getCapacityData,
      getProvinceOnTimeRate,
      styleCompatible,
      fullScreen,
      detailClick
    }
  }
}
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
